<template>
  <sub-page
      :title="$t('ProjectMeetings')"
      icon="mdi-eye"
      v-model="$store.state.settings.pageData"
  >
    <ws-data-table
        :items="items"
        :headers="headers"
    >
    </ws-data-table>

  </sub-page>
</template>

<script>
export default {
  name: "ProjectParticipants",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      entity : {
        name : 'Super project'
      },
      items : [
        { name : 'Very important meeting'    ,     date : 12312333  },
        { name : 'Student lecture'           ,    date : 12312333  }
      ]
    }
  },

  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Date') , value : 'date' }
      ]
    },
  }
}
</script>

<style scoped>

</style>